var dataTableClickCount = 0;
var clickTimer = null;
export function singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc) {
  dataTableClickCount++;

  if (dataTableClickCount === 1) {
    clickTimer = setTimeout(function() {
      dataTableClickCount = 0;
      singleClickFunc(item);
    }, 250);
  } else if (dataTableClickCount === 2) {
    clearTimeout(clickTimer);
    dataTableClickCount = 0;
    doubleClickFunc(item);
  }
}
